import { useEffect, useRef, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Badge,
  Box,
  ClickAwayListener,
  List,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Popper,
  Typography,
  useMediaQuery,
} from '@mui/material';

// project import
import MainCard from 'components/MainCard';
import IconButton from 'components/@extended/IconButton';
import Transitions from 'components/@extended/Transitions';

// assets
import { BellOutlined } from '@ant-design/icons';
import { dispatch } from 'store';
import { useSelector } from 'react-redux';
import { deleteNotification, getNotificationsList, readAllNotifications } from '_api/notifications';
import { DeleteOutlined } from '@mui/icons-material';

import { NotificationIcon } from './NotificationIcon';
import NotificationDescription from './NotificationDescription';
import { getNotificationColor } from './constants';
import { getToken, onMessageListener } from 'firebaseInit';
import { editNotification } from 'store/reducers/notifications';
import { getLinksList } from '_api/links';
import { getDashboardOverviewDataList } from '_api/dashboard';
import { momentWithUserTZ } from 'utils/momentWithTz.js';

// sx styles
const avatarSX = {
  width: 36,
  height: 36,
  fontSize: '1rem',
};

const actionSX = {
  mt: '6px',
  ml: 1,
  top: 'auto',
  right: 'auto',
  alignSelf: 'flex-start',
  transform: 'none',
};

const handleEventNotification = (eventFB) => {
  const eventName = eventFB?.event_name;

  if (!eventName) {
    return;
  }

  switch (eventName) {
    case eventName.includes('App\\Notifications'):
      dispatch(getNotificationsList());
      break;
    case 'NewNotificationEvent':
      dispatch(getNotificationsList());
      break;
    case 'NotificationUpdatedEvent':
      {
        const { notification_uuid, ...notificationItem } = eventFB;

        if (notification_uuid) {
          dispatch(
            editNotification({
              notification_uuid,
              notificationItem,
            }),
          );
        }
      }
      break;
    case 'FileFilledEvent':
      dispatch(getLinksList());
      dispatch(getDashboardOverviewDataList());
      dispatch(getNotificationsList());
      break;
    default:
      break;
  }
};

// ==============================|| HEADER CONTENT - NOTIFICATION ||============================== //

const Notification = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
  const {
    user: { uuid: userID = '' },
  } = useSelector(state => state.auth);

  const { notificationsList } = useSelector(state => state.notifications);
  const unReadNotificationsCount = notificationsList.filter(({ is_read }) => !is_read).length;
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [notificationFB, setNotificationFB] = useState(null);
  const [isNotify, setNotify] = useState(false);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const iconBackColorOpen = theme.palette.mode === 'dark' ? 'grey.200' : 'grey.300';
  const iconBackColor = theme.palette.mode === 'dark' ? 'background.default' : 'grey.100';

  useEffect(() => {
    handleEventNotification(notificationFB);
  }, [notificationFB]);

  useEffect(() => {
    dispatch(getNotificationsList());
  }, [userID, isNotify]);

  useEffect(() => {
    if (open) {
      dispatch(readAllNotifications());
    }
  }, [open]);

  useEffect(() => {
    const fetchToken = async () => {
      await getToken();
    };

    fetchToken();

    const unsubscribe = onMessageListener(
      (payload) => {
        setNotify(true);
        setNotificationFB(payload.data);
      },
      error => console.error('Failed to receive notification:', error),
    );

    return unsubscribe;
  }, []);

  return (
    <>
      <Box sx={{ flexShrink: 0, ml: 0.75 }}>
        <IconButton
          color="secondary"
          variant="light"
          sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor }}
          aria-label="open profile"
          ref={anchorRef}
          aria-controls={open ? 'profile-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <Badge badgeContent={unReadNotificationsCount} color="primary">
            <BellOutlined />
          </Badge>
        </IconButton>
        <Popper
          placement={matchesXs ? 'bottom' : 'bottom-end'}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          popperOptions={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [matchesXs ? -5 : 0, 9],
                },
              },
            ],
          }}
        >
          {({ TransitionProps }) => (
            <Transitions type="fade" in={open} {...TransitionProps}>
              <Paper
                sx={{
                  boxShadow: theme.customShadows.z1,
                  width: 400,
                  [theme.breakpoints.down('md')]: {
                    maxWidth: 285,
                  },
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MainCard
                    title="Notification"
                    titleSX={{
                      fontSize: '16px !important',
                      lineHeight: '24px !important',
                      fontWeight: '500 !important',
                      color: '#322E59 !important',
                    }}
                    contentSX={{ p: '16px 20px' }}
                    elevation={0}
                    border={false}
                    content={false}
                    secondary={false}
                  >
                    <List
                      component="nav"
                      sx={{
                        p: 0,
                        '& .MuiListItemButton-root': {
                          py: 1,
                          bgcolor: '#fff',
                          '&.Mui-selected': { color: 'text.primary' },
                          '& .MuiAvatar-root': avatarSX,
                          '& .MuiListItemSecondaryAction-root': {
                            ...actionSX,
                            position: 'relative',
                          },
                        },
                        maxHeight: 355,
                        overflow: 'auto',
                      }}
                    >
                      {notificationsList.map(
                        (
                          {
                            uuid: notificationID,
                            heading,
                            description,
                            type,
                            created_at,
                            onNotificationClick,
                            data = {},
                          },
                          index,
                        ) => (
                          <ListItemButton
                            divider
                            key={notificationID}
                            selected={unReadNotificationsCount > 0}
                            onClick={onNotificationClick ?? null}
                          >
                            <NotificationIcon notification={notificationsList[index]} />
                            <ListItemText
                              primary={
                                <>
                                  <Typography color={getNotificationColor(type)} fontSize={16} fontWeight={400}>
                                    {heading}
                                  </Typography>
                                  <NotificationDescription description={description} notificationData={data} />
                                </>
                              }
                              secondary={
                                <Typography color="#8C8C8C" fontSize={12} fontWeight={400}>
                                  {momentWithUserTZ(created_at)
                                    .fromNow()}
                                </Typography>
                              }
                            />
                            <ListItemSecondaryAction>
                              <Typography color="#8C8C8C" fontSize={12} fontWeight={400} noWrap>
                                {momentWithUserTZ(created_at)
                                  .format('h:mm a')}
                              </Typography>
                              <IconButton
                                variant="text"
                                color="inherit"
                                aria-label="delete"
                                sx={{ background: 'none !important' }}
                                onClick={(event) => {
                                  event.preventDefault();
                                  event.stopPropagation();

                                  dispatch(deleteNotification({ notificationID }));
                                }}
                              >
                                <DeleteOutlined color="disabled" />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItemButton>
                        ),
                      )}
                    </List>
                  </MainCard>
                </ClickAwayListener>
              </Paper>
            </Transitions>
          )}
        </Popper>
      </Box>
    </>
  );
};

export default Notification;
