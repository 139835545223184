import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from 'utils/axios';
import { removeEmptyValues } from 'utils/objectUtils';

export const getIpExclusionsList = createAsyncThunk(
  'ipExclusions/getIpExclusionsList',
  async (
    {
      currentPage = 0,
      currentRowCount = 25,
      currentSearchValue = '',
      currentFilters = [],
      currentFilterLink = 'and',
      currentSortData: { field: sort = 'created_at', sort: sort_direction = 'desc' } = {},
      isLazyLoad = false,
    },
    { rejectWithValue },
  ) => {
    try {
      const queryParams = {
        page: currentPage,
        per_page: Number(currentRowCount),
        search: currentSearchValue,
        filters: currentFilters,
        filter_link: currentFilterLink,
        sort,
        sort_direction,
      };

      const response = await axiosInstance.get(`/exclusionIps`, {
        params: removeEmptyValues(queryParams),
        data: {},
      });

      return { ...response.data, isLazyLoad };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const submitIpExclsuion = createAsyncThunk(
  'ipExclusions/submitIpExclsuion',
  async ({ ipExclusion = {}, isEditMode = false }, { rejectWithValue }) => {
    try {
      let response;
      if (isEditMode) {
        const ipExclusionId = ipExclusion.uuid ?? '';
        response = await axiosInstance.post(`/exclusionIps/${ipExclusionId}`, ipExclusion);
      } else {
        response = await axiosInstance.post(`/exclusionIps`, ipExclusion);
      }
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteIpExclusion = createAsyncThunk(
  'ipExclusions/deleteIpExclusion',
  async ({ ipExclusionId = '' }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/exclusionIps/${ipExclusionId}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getIpExclusionById = createAsyncThunk(
  'ipExclusions/getIpExclusionById',
  async ({ ipExclusionId = '' }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/exclusionIps/${ipExclusionId}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const bulkDeleteIpExclusions = createAsyncThunk(
  'ipExclusions/bulkDeleteIpExclusions',
  async ({ selectedRowIDs = [] }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/exclusionIps/bulkDelete`, {
        exclusion_ip_uuids: selectedRowIDs,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const importCSV = createAsyncThunk(
  'ipExclusions/importCSV',
  async ({ CSVFileData = {} }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/exclusionIps/import`, CSVFileData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getIpExclusionStatistics = createAsyncThunk(
  'ipExclusions/getIpExclusionStatistics',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get('/exclusionIps/statistics');
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
