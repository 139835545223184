import cloneDeep from 'lodash/cloneDeep';
// TODO: remove
Object.defineProperty(Array.prototype, 'move', {
  value(from, to) {
    const newArray = cloneDeep(this);
    newArray.splice(to, 0, newArray.splice(from, 1)[0]);
    return newArray;
  },
  enumerable: false,
});

Object.defineProperty(Array.prototype, 'removeItemWithIndex', {
  value(removedIndex) {
    return this.filter((_, elementIndex) => elementIndex !== removedIndex);
  },
  enumerable: false,
});
